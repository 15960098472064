<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-container>
      <v-row>
        <v-col cols="12" lg="6">
          <v-sheet class="pa-8 mb-8">
            <div class="text-center">
              <h1 class="mb-5">{{ $t("offerings-events-creative") }}</h1>
            </div>
            <div class="text-center mb-5">
              <p
                v-for="paragraph in $t('offerings-events-creative-text')"
                :key="paragraph"
              >
                {{ paragraph }}
              </p>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="6">
          <v-sheet class="pa-8 mb-8">
            <div class="text-center">
              <h1 class="mb-5">{{ $t("offerings-events-active") }}</h1>
            </div>
            <div class="text-center mb-5">
              <p
                v-for="paragraph in $t('offerings-events-active-text')"
                :key="paragraph"
              >
                {{ paragraph }}
              </p>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="6">
          <v-sheet class="pa-8 mb-8">
            <div class="text-center">
              <h1 class="mb-5">{{ $t("offerings-events-cook") }}</h1>
            </div>
            <div class="text-center mb-5">
              <p
                v-for="paragraph in $t('offerings-events-cook-text')"
                :key="paragraph"
              >
                {{ paragraph }}
              </p>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="6">
          <v-sheet class="pa-8 mb-8">
            <div class="text-center">
              <h1 class="mb-5">{{ $t("offerings-events-lecture") }}</h1>
            </div>
            <div class="text-center mb-5">
              <p
                v-for="paragraph in $t('offerings-events-lecture-text')"
                :key="paragraph"
              >
                {{ paragraph }}
              </p>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row class="mt-n8">
        <v-spacer v-if="!this.$vuetify.breakpoint.xs"></v-spacer>
        <v-col
          v-for="(button, index) in this.buttons"
          :key="index"
          align="center"
        >
          <v-btn color="accent" :to="button.url">
            <v-icon class="mr-1">{{ button.icon }}</v-icon>
            {{ $t(button.slug) }}
          </v-btn>
        </v-col>
        <v-spacer v-if="!this.$vuetify.breakpoint.xs"></v-spacer>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "events",
  components: {
    Breadcrumbs
  },
  data: () => ({
    buttons: [
      {
        slug: "calendar",
        icon: "mdi-calendar-multiple",
        url: "/calendar"
      },
      {
        slug: "membership",
        icon: "mdi-account-multiple-plus-outline",
        url: "/association/membership"
      }
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("offerings"),
          disabled: true
        },
        {
          text: this.$t("events"),
          disabled: true
        }
      ];
    }
  }
};
</script>
